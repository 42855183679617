import React, { useState, useEffect, useCallback } from "react";
import RouteName from "../../../../services/routename";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import SubmittedIdeaRepo from "../../../../repositories/user-repo/SubmittedIdeaRepo";
import ChallengeRepo from "../../../../repositories/user-repo/ChallengeRepo";
import Breadcrumbs from "./components/Breadcrumbs";
import FilterRepo from "../../../../repositories/user-repo/FilterRepo";
import Paginate from "../../../../components/paginate/Paginate";
import DetailSubmittedRepo from "../../../../repositories/user-repo/DetailSubmittedRepo";
import Cookies from "js-cookie";
import EncryptDecrypt from "../../../../utility/encrypt-decrypt";
import DropdownFilter from "../../../../components/DropdownFilter";
import ListSubmitted from "./components/ListSubmitted";
import ListSubmittedSm from "./components/ListSubmittedSm";
import GridSubmitted from "./components/GridSubmitted";
import Banner from "../../../../components/Banner";
import GeneralUtility from "../../../../utility/GeneralUtility";
import DropdownData from "../../../../components/Dropdown/Dropdown";

const SubmitedIdea = () => {
  
  let { id } = useParams();
  let location = useLocation();
  
  const queryParams = new URLSearchParams(location.search);

  const isPageInfo = queryParams.get('page') || 0; 
  const isEvaluation = queryParams.get('evaluation') || "all"; 

  const pageNumber = isPageInfo && !isNaN(Number(isPageInfo)) ? Number(isPageInfo) : null;

  const view = queryParams.get('isview') || "0"; 
  
  const [selectedPage, setSelectedPage] = useState(0);

  let identification = location.pathname.includes("archived-challenges");

  Cookies.set("idChallenge", EncryptDecrypt.enc(id), { secure: true });

  const navigate = useNavigate();

  const [limit] = useState(12);
  const [page, setPage] = useState([]);
  const [listView, setlistView] = useState(true);
  const [listSubmitted, setListSubmitted] = useState([]);
  const [banner, setBanner] = useState([]);
  const [evaluation, setEvaluation] = useState(isEvaluation);

  const path = GeneralUtility.getUrlBeforeParam(location.pathname);
  
  // eslint-disable-next-line
  const [transaction] = useState({});
  const [listEvaluation, setListEvaluation] = useState([]);
  // eslint-disable-next-line
  const [listTransaction, setListTransaction] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState({ id: "DESC", name: "Recent" });

  const listFilter = [
    { id: "DESC", name: "Recent" },
    { id: "ASC", name: "oldest" },
  ];

  /**
   * count view ideas
   * @param {*} e
   */
  const countView = async (e) => {
    // eslint-disable-next-line
    const result = await DetailSubmittedRepo.postView({
      transactionmychallange: { id: e },
    });
  };

  /**
   * goto detail idea page
   * @param {*} e
   */
  const detailSubmitted = (e) => {
    Cookies.set("ideasPage", selectedPage);
    Cookies.set("isListView", listView);

    countView(e);
    if (identification === true) {
      navigate(RouteName.detailArcIdea + "/" + e + `?isview=${view}&evaluation=${evaluation}&page=${pageNumber}`);
    } else {
      navigate(RouteName.detailIdea + "/" + e + `?isview=${view}&evaluation=${evaluation}&page=${pageNumber}`);
    }
  };

  const getDetailChallenge = useCallback(async () => {
    const result = await ChallengeRepo.getDetailChallenges({ id: id });
    if (result !== null) {
      let listChallenges = result;
      setBanner({
        id: listChallenges.id,
        title: listChallenges.title,
        banner_image: listChallenges.banner_image,
        slug: listChallenges.slug,
        status: listChallenges.status,
      });
      Cookies.set("slugChallenge", EncryptDecrypt.enc(listChallenges.slug), { secure: true  });
    } else {
      setBanner(null);
    }
  }, [id]);

  const getSubmittedList = useCallback(
    async (pageNumber) => {
      setLoading(true);
      const result = await SubmittedIdeaRepo.getSubmittedIdea({
        offset: pageNumber,
        limit: limit,
        challange: id,
        status: transaction.id,
        evaluation: evaluation === "all"||evaluation=== "" ? "" : evaluation,
        sort: sort.id,
      });

      let listSubmitted = result !== null ? result.data : [];
      let listPage = result !== null ? result : [];
      setListSubmitted(listSubmitted);
      setPage(listPage);
      Cookies.set("currentPage", listPage.currentPage);
      setLoading(false);
    },
    [evaluation, transaction.id, id, limit, sort.id]
  );

  const getFilterTrans = async () => {
    const result = await FilterRepo.getTransaction();
    let listTrans = result.data.data;
    listTrans.unshift({ id: "all", name: "All Ideas" });
    setListTransaction(listTrans);
  };

  const getFilterEval = async () => {
    const result = await FilterRepo.getEvaluation();
    let listEval = result.data.data;
    listEval.unshift({ id: "", name: "All Ideas" });
    setListEvaluation(listEval);
  };

  const paginate = (event) => {
    Cookies.set("ideasPage", event.selected);
    getSubmittedList(event.selected);
    setSelectedPage(event.selected);
    const url = `${GeneralUtility.redirectPage(path)}/${id}?isview=${view}&evaluation=${evaluation}&page=${event.selected}`;
    navigate(url);  
  };

  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    getSubmittedList(pageNumber);
    getDetailChallenge();
    getFilterTrans();
    getFilterEval();
  }, [sort, evaluation, getDetailChallenge,getSubmittedList]);

  const handleSetListViewFalse = () => {
    const url = `${GeneralUtility.redirectPage(path)}/${id}?isview=0&evaluation=${evaluation}&page=${pageNumber}`;
    navigate(url);
    setlistView(false)
    //getSubmittedList(pGet);
  }

  const handleSetListViewTrue = () => {
    const url = `${GeneralUtility.redirectPage(path)}/${id}?isview=1&evaluation=${evaluation}&page=${pageNumber}`;
    navigate(url);
    setlistView(true)
    //getSubmittedList(pageNumber);
  }

  const handleSelectedEvaluation = (e) => {
    setEvaluation(e.id)
    const url = `${GeneralUtility.redirectPage(path)}/${id}?isview=${view}&evaluation=${e.id}&page=0`;
    navigate(url);
  
   // getSubmittedList(pageNumber)
  }
    
  return (
    <>
      {banner !== null ? (
        <div className="container mx-auto">
          <div className="m-6">
            <Breadcrumbs
              identification={identification}
              banner={{
                slug: banner.slug,
                banner: banner.title,
                id: banner.id,
              }}
            />
          </div>
          <div>
            <Banner
              detail={{ banner: banner.banner_image, title: banner.title }}
              loading={loading}
            />
          </div>
          <div className="m-6 space-y-4">
            <div className="justify-end flex flex-col lg:flex lg:flex-row space-y-2">
              <div className="flex items-center space-x-3 justify-between">
                <div className="flex space-x-2">
                  <button
                    className={` ${
                      view === "1"
                        ? "bg-freshGreen text-baseGray"
                        : "bg-gray-300 text-freshGreen"
                    } w-8 h-8`}
                    onClick={handleSetListViewTrue}
                  >
                    <img
                      src="/assets/global/img/grid.svg"
                      className="ml-1.5"
                      alt="grid"
                    />
                  </button>
                  {/* list */}
                  <button
                    className={` ${
                      view === "1"
                        ? "bg-gray-300 text-freshGreen"
                        : "bg-freshGreen text-baseGray"
                    } w-8 h-8`}
                    onClick={handleSetListViewFalse}
                  >
                    <img
                      src="/assets/global/img/list.svg"
                      className="ml-1.5"
                      alt="list"
                    />
                  </button>
                </div>
              </div>
              <div className="flex space-x-2 justify-self-end">
                <DropdownData title="show : " selected={evaluation} onChange={handleSelectedEvaluation} resList={listEvaluation.sort((a, b) => a.step_index - b.step_index)} />
                <DropdownFilter title="SORT BY :" selected={sort} setSelected={setSort} resList={listFilter} />
              </div>
              <div className="lg:hidden"></div>
            </div>

            <div className={` ${view === "1" ? "mx-auto" : "hidden"}`}>
              <GridSubmitted
                identification={identification}
                listSubmitted={listSubmitted}
                detailSubmitted={detailSubmitted}
                loading={loading}
              />
            </div>

            <div className={` ${view === "1" ? "hidden" : "mx-auto"}`}>
              <ListSubmitted
                identification={identification}
                listSubmitted={listSubmitted}
                detailSubmitted={detailSubmitted}
                loading={loading}
              />

              {/* mobile list design */}
              <ListSubmittedSm
                identification={identification}
                listSubmitted={listSubmitted}
                detailSubmitted={detailSubmitted}
                loading={loading}
              />
            </div>

            <div className="pb-5">
              <Paginate paginate={paginate} page={page} defPage={isPageInfo} />
            </div>
          </div>
        </div>
      ) : (
        <div className="container mx-auto">
          <div className="text-center font-bold text-xl mt-10">Challenge Not Found</div>
        </div>
      )}
    </>
  );
};

export default SubmitedIdea;
