import React, { useEffect, useState, useCallback } from "react";
import Challenges from "./components/Challenges";
import ChallengeRepo from "../../../repositories/user-repo/ChallengeRepo";
import FilterRepo from "../../../repositories/user-repo/FilterRepo";
import Paginate from "../../../components/paginate/Paginate";
import Skeleton from "../../../components/skeleton/Skeleton";
import GeneralUtility from "../../../utility/GeneralUtility";
import {useLocation,useNavigate} from 'react-router-dom'
import DropdownData from "../../../components/Dropdown/Dropdown";
import RouteName from "../../../services/routename";

const LatestChallenges = () => {

  const navigate = useNavigate();
  let location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const urlpage = parseInt(queryParams.get('page')) || 0; 
  const urlType = queryParams.get('type') || "all"; 
  const urlStatus  = queryParams.get('status') || "active"; 
  const urlSearch  = queryParams.get('search') || ""; 
  
  const [loading, setLoading] = useState(false);
  const [listType, setListType] = useState([]);
  const [listStatus, setListStatus] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [limit] = useState(12);
  const [page, setPage] = useState([]);
  const [selectedType, setSelectedType] = useState(urlType);
  const [selectedStat, setSelectedStat] = useState(urlStatus);  
  const [searchTerm, setSearchTerm] = useState(urlSearch);

  const getAllChallenge = useCallback(
    async (pageNumber, search,status,type) => {

      setLoading(true);

      try{

        const body = {
          offset: pageNumber,
          limit: limit,
          search: urlSearch,
        };
        
        if(selectedStat !== "all"){
          body.status = status;  
          }

        if (selectedType !== "all") {
          body.type_idea = type;
        }

        const result = await ChallengeRepo.getChallenges(body);
        
        let listChallenges = result !== null ? result.data : [];
        let listPage = result !== null ? result : [];
        setChallenges(listChallenges);
        setPage(listPage);
        setLoading(false);
      }catch(error){
        setLoading(false);
      }
    },
    [limit, selectedType, selectedStat,urlSearch]
  );


  const getFilterType = async () => {
    const result = await FilterRepo.getTypeIdea();
    if (result !== undefined) {
      if (result.status === 200) {
        let listType = result.data.data;
        listType.unshift({ id: "all", name: "All Challenges" });
        setListType(listType);
      }
    }
  };

  const getFilterStatus = async () => {
    const result = await FilterRepo.getStatus();
    if (result !== undefined) {
      if (result.status === 200) {
        let listStatus = result.data.data;
        listStatus.unshift({ id: "all", name: "all" });
        let newArr = listStatus.filter((items) => items.name !== "up coming");
        setListStatus(newArr);
      }
    }
  };

  // Change Page
  const paginate = (event) => {
    navigate(RouteName.userLatestChallenges + "?" + "type="+ urlType + "&status="+ urlStatus + "&page=" + event.selected +"&search=" + urlSearch)
    getAllChallenge(event.selected, searchTerm, selectedStat, selectedType);
  //  Cookies.set("challengePage", event.selected)
  };

  useEffect(() => {
    GeneralUtility.hitRefreshToken();
    GeneralUtility.countViewPage("Latest_Challenge_Page");
    getAllChallenge(urlpage, searchTerm, selectedStat, selectedType);
    getFilterType();
    getFilterStatus();
  }, [getAllChallenge]);

  const handleSelected = (value) => {
   // getAllChallenge(0,urlSearch,urlStatus,value.id)
   navigate(RouteName.userLatestChallenges + "?" + "type="+ value.id + "&status="+ urlStatus + "&page=" + urlpage + "&search=" + urlSearch)
    setSelectedType(value.id)
  }

  const handleSelectedStat = (val)=>{
   // getAllChallenge(0,urlSearch,urlStatus,val.id)
    navigate(RouteName.userLatestChallenges + "?" + "type="+ urlType + "&status="+ val.name + "&page=" + urlpage+"&search=" + urlSearch)
    setSelectedStat(val.name)
  }

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
        let val = e.target.value;      
        setSearchTerm(val);
        setSelectedStat("all");
        setSelectedType("all");
        navigate(RouteName.userLatestChallenges + "?" + "type=" + "all" + "&status=" + "all" + "&page=" + 0 + "&search=" + val);
       // getAllChallenge(0, val, "all", "all");
        setSearchTerm("")
    }
};

  const handleButtonClick = () => {
    setSelectedStat("all");
    setSelectedType("all");
    navigate(RouteName.userLatestChallenges + "?" + "type=" + "all" + "&status=" + "all" + "&page=" + 0 + "&search=" + searchTerm);
  };

  return (

    <div className="container mx-auto bx_latest_challenges">
      <div className="m-6 bx_sub_latest">
        <div className="mx-auto mt-8 px-1">
          <div className="flex justify-center bg-white w-full">
            <div className="w-full">
              <div className="tx_top_page_latest lg:hidden">
                Latest Challenges
              </div>

              <div className="grid grid-cols-2 justify-between gap-3">
                <div className="relative w-28 md:w-40 lg:w-60 bx_txt_search_latest">
                  <input
                    autoComplete="off"
                    type="text"
                    className="border border-gray-300 text-gray-800 text-xs rounded-sm focus:ring-freshGreen focus:border-freshGreen block w-full pr-10 p-2"
                    placeholder="What are you looking for? ..."
                    onChange={handleSearch}
                    onKeyDown={handleSearch}
                  />
                  <button
                    onClick={() => handleButtonClick()}
                    type="button"
                    className="absolute top-2 right-0 flex items-center pr-3"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="flex justify-self-end">
                  <div
                    name="sortby"
                    className="py-0 h-10 text-xs border-none flex space-x-3 "
                  >
                    <DropdownData
                      title="Show :"
                      selected={selectedType}
                      onChange={handleSelected}
                      resList={listType}
                    />
                    <DropdownData
                      title="Sort By :"
                      selected={selectedStat}
                      onChange={handleSelectedStat}
                      resList={listStatus}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-baseGray m-7 p-4 lg:p-6 bx_sub_latest_list">
        <div className="text-lg lg:text-xl font-semibold text-center p-5 ht_global_page_title">
          Get Rewarded With Our Latest Challenges
        </div>
        {!loading ? (
          <div className="mx-auto mt-3 md:px-2 lg:px-6">
            <Challenges challenges={challenges} loading={loading} />
          </div>
        ) : (
          <div className="mx-auto mt-3 md:px-2 lg:px-6">
            <Skeleton col={4} height={350} />
          </div>
        )}
        <div className="p-3 mx-auto mt-4 md:mt-1">
          <Paginate paginate={paginate} page={page} defPage={urlpage} />
        </div>
      </div>
    </div>
  );
};

export default LatestChallenges;
