import React,{useMemo} from 'react'
import RouteName from '../../../../../services/routename';
import { Link,useNavigate,useLocation } from 'react-router-dom';
import RightIco from '../../../../../components/icons/RightIco'


const Breadcrumbs = ({ identification, detailIdea, challenge, idChallenge, slugChallenge, typeDetail }) => {
  const navigate = useNavigate();

  let location = useLocation();
  console.log(identification);
  

  const queryParams = new URLSearchParams(location.search);
  const view = queryParams.get('isview') || 0; 
  
  const isPageInfo = queryParams.get('page'); 
  const isEvaluation = queryParams.get('evaluation') || "all"; 
  
  const handleTo = useMemo(() => {
    navigate(-1, { replace: true });

  },[navigate])
  
  const handleBack = () => {
    if(identification){
      navigate(RouteName.sumbitedArcIdea + "/" + idChallenge + `?isview=${view}&evaluation=${isEvaluation}&page=${isPageInfo}`)

    } else{
      navigate(RouteName.sumbitedIdea + "/" + idChallenge + `?isview=${view}&evaluation=${isEvaluation}&page=${isPageInfo}`)

    }
  }

  return (
    <>
      {!typeDetail ?
        <div>
          <nav className="flex mb-3 text-[10px]" aria-label="Breadcrumb">
            <div className="inline-flex items-center space-x-1 md:space-x-3">
              <div className="inline-flex items-center">
                <Link
                  to={RouteName.userHome}
                  className="inline-flex items-center  font-medium text-gray-500 hover:text-gray-900"
                >
                  <svg
                    className="w-4 h-4 mr-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                  </svg>
                </Link>
              </div>
              <div>
                <div className="flex items-center">
                  <RightIco />
                  {identification ?
                    <Link
                      to={RouteName.userLatestChallenges}
                      className="ml-1  font-medium text-gray-500 hover:text-gray-900 md:ml-2"
                    >
                      Archived Challenges
                    </Link>
                    :
                    <Link
                      to={RouteName.userLatestChallenges}
                      className="ml-1  font-medium text-gray-500 hover:text-gray-900 md:ml-2 cursor-pointer"
                    >
                      Latest Challenges
                    </Link>
                  }
                </div>
              </div>
              <div>
                <div className="flex items-center">
                  <RightIco />
                  {identification ?
                    <div>
                      <Link
                        to={RouteName.userArchivedChallenges + "/" + slugChallenge}
                        className="ml-1  font-medium text-gray-500 hover:text-gray-900 md:ml-2 hidden md:block lg:block"
                      >
                        {challenge.title ? (challenge.title.length > 80 ? challenge.title.substring(0, 80) : challenge.title) : challenge.title}
                      </Link>
                      <Link
                        to={RouteName.userArchivedChallenges + "/" + slugChallenge}
                        className="ml-1  font-medium text-gray-500 hover:text-gray-900 md:ml-2 md:hidden lg:hidden"
                      >
                        {challenge.title ? (challenge.title.length > 60 ? challenge.title.substring(0, 60) : challenge.title) : challenge.title}
                      </Link>
                    </div> :
                    <div>
                      <Link
                        to={RouteName.userLatestChallenges + "/" + slugChallenge}
                        className="ml-1  font-medium text-gray-500 hover:text-gray-900 md:ml-2 hidden md:block lg:block"
                      >
                        {challenge.title ? (challenge.title.length > 80 ? challenge.title.substring(0, 80) : challenge.title) : challenge.title}
                      </Link>
                      <Link
                        to={RouteName.userLatestChallenges + "/" + slugChallenge}
                        className="ml-1  font-medium text-gray-500 hover:text-gray-900 md:ml-2 md:hidden lg:hidden"
                      >
                        {challenge.title ? (challenge.title.length > 60 ? challenge.title.substring(0, 60) : challenge.title) : challenge.title}
                      </Link>
                    </div>
                  }
                </div>
              </div>
              <div aria-current="page">
                <div className="flex items-center">
                  <RightIco />
                  {identification ?
                    <div
                      onClick={handleBack}
                      className="ml-1  font-medium text-gray-500 hover:text-gray-900 md:ml-2 cursor-pointer"
                    >
                     Submitted Idea
                    </div>
                    :
                    <div
                      onClick={handleBack}
                      className="ml-1  font-medium text-gray-500 hover:text-gray-900 md:ml-2 cursor-pointer"
                    >
                      Submitted Idea
                    </div>
                  }
                </div>
              </div>
              <div aria-current="page">
                <div className="flex items-center">
                  <RightIco />
                  <span className="ml-1  font-medium text-gray-700 md:ml-2 hidden md:block lg:block">
                    {detailIdea.title ? (detailIdea.title.length > 60 ? detailIdea.title.substring(0, 60) : detailIdea.title) : detailIdea.title}
                  </span>
                  <span className="ml-1  font-medium text-gray-700 md:ml-2 md:hidden lg:hidden">
                    {detailIdea.title ? (detailIdea.title.length > 40 ? detailIdea.title.substring(0, 40) : detailIdea.title) : detailIdea.title}
                  </span>
                </div>
              </div>
            </div>
          </nav>
        </div>
        :
        <div>
          <nav className="flex mb-3 text-[10px]" aria-label="Breadcrumb">
            <div className="inline-flex items-center space-x-1 md:space-x-3">
              <div className="inline-flex items-center">
                <Link
                  to={RouteName.userHome}
                  className="inline-flex items-center  font-medium text-gray-500 hover:text-gray-900"
                >
                  <svg
                    className="w-4 h-4 mr-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                  </svg>
                </Link>
              </div>
              <div className="flex items-center">
                <RightIco />
                <Link
                  to={RouteName.userMyIdeas}
                  className="ml-1  font-medium text-gray-500 hover:text-gray-900 md:ml-2"
                >
                  My Ideas
                </Link>
              </div>
              <div className="flex items-center">
                <RightIco />
                <div
                  className="ml-1  font-medium text-gray-500 hover:text-gray-900 md:ml-2"
                >
                  {detailIdea.title}
                </div>
              </div>
            </div>
          </nav>
        </div>
      }
    </>
  );
};

export default Breadcrumbs
